import { usePopup } from '@/compositions/popup';
import { genMenuItemEdit } from '@/utils/list-generators';
import {
  genMenuItemDefault,
  genMenuItemDelete,
  genMenuItemShowHistory
} from '@/utils/list-generators/menu';
import { objectService } from '../../../api';
import { objectItemTypes } from '@/modules/objects/ui/object-card/general/item-helpers';

export function useGeoItemMenu() {
  const popup = usePopup();
  return {
    genMenu: (geoItem, objectId) => {
      const { id, hardLinked } = geoItem;
      return [
        genMenuItemEdit(() => {
          popup.open({
            component: () => import('./GeoItemEdit.vue'),
            props: {
              geoItemId: id,
              objectId
            }
          });
        }),
        genMenuItemShowHistory(null, {
          dsabled: true
        }),
        genMenuItemDefault(
          {
            id: 'alarms',
            icon: '$alert',
            title: 'Alarms'
          },
          {
            click: () => {
              popup.open({
                component: () => import('./ObjectItemAlarmsEdit.vue'),
                props: {
                  objectId,
                  objectItemId: id,
                  objectItemType: objectItemTypes.geoItem
                }
              });
            }
          }
        ),
        genMenuItemDelete(
          () => {
            popup.openConfirm({
              component: () =>
                import('@/components/popup/PopupConfirmAction.vue'),
              props: {
                title: 'Delete geo item?',
                onConfirm: async () => {
                  await objectService.delete(id);
                }
              }
            });
          },
          {
            disabled: !!hardLinked
          }
        )
      ];
    }
  };
}
