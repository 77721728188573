import { usePopup } from '@/compositions/popup';
import { genMenuItemEdit } from '@/utils/list-generators';
import { objectPropertyService } from '@/modules/common/api';
import { typeCast } from '@/provider/utils';

export function useCustomPropertyItemMenu() {
  const popup = usePopup();
  return {
    genMenu: propertyItem => {
      const { id, type } = propertyItem;
      return [
        genMenuItemEdit(() => {
          popup.open({
            component: () => import('@/components/popup/EditProperty.vue'),
            props: {
              currentItem: propertyItem,
              onConfirm: value =>
                objectPropertyService.update(id, {
                  value: typeCast(type, value)
                })
            }
          });
        })
      ];
    }
  };
}
